@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap);
@import url(https://pro.fontawesome.com/releases/v5.10.0/css/all.css);
body {
  font-family: 'Source Sans Pro', sans-serif;
}

.large-text{
  font-size: 1.5rem;
}

*::selection{
  background-color: #464644;
  color: white;
}

*{
  padding: 0px;
  margin: 0px;
}

p {
    text-align: justify;
}

.footer {
  width: 100%;
  height: 250px;
  background-color: #464646;
  display: -webkit-flex;
  display: flex;
}

img {
  width: 100%;
}

.image-desc {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
}

.image-desc > div {
  width: 50%;
}

.image-desc .desc {
  padding: 25px 4rem;
}

.footer-logo{
  width: 50%;
  letter-spacing: 1px;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.footer-social-links {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.content-link{
  font-weight: bold;
}

.image-desc:nth-child(even){
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.error404 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 300px;
  width: 100%;
}

.error404 p {
  font-size: 25px;
}

[aria-hidden="true"] { display: none!important; }

[aria-hidden="false"] { display: block; }

.spinner{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media only screen and (max-width: 600px) {
  .image-desc {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .image-desc:nth-child(even){
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .image-desc > div {
    width: 100%;
  }

  .image-desc .desc {
    padding: 25px 2rem;
  }
}



